import { isPossiblePhoneNumber, isValidNumber } from "libphonenumber-js";
export interface ProxyObjectType {}
const emptyProxyObject: ProxyObjectType = new Proxy({}, {});
export const isEmpty = (val: any) => {
  if (val === emptyProxyObject) return true;
  if (val === undefined) return true;

  if (
    typeof val == "function" ||
    typeof val == "number" ||
    typeof val == "boolean" ||
    Object.prototype.toString.call(val) === "[object Date]"
  )
    return false;

  if (val == null || val.length === 0)
    // null or 0 length array
    return true;

  if (typeof val == "object") if (Object.keys(val).length === 0) return true;

  return false;
};

export const dateToMili = (date: any) => {
  const dateArray = date.split("/");
  const dd = dateArray[0];
  const mm = dateArray[1];
  const yyyy = dateArray[2];
  const newDate = new Date(`${mm}/${dd}/${yyyy} 00:00:00`);
  return newDate.getTime();
};

export const latestNews = (news: any[], isFeatureCheck: boolean = false) => {
  news.forEach((item) => {
    if (item?.childMdx?.frontmatter?.date) {
      const date = item.childMdx.frontmatter.date;
      item.childMdx.frontmatter["dateInMili"] = dateToMili(date);
    }
  });
  let filterFeaturedNews: any = [];
  if (isFeatureCheck && news) {
    filterFeaturedNews = news.filter(
      (item) => item?.childMdx?.frontmatter?.featured === true
    );
  } else {
    filterFeaturedNews = news;
  }
  const newsList =
    filterFeaturedNews &&
    filterFeaturedNews.sort((a: any, b: any) => {
      const value1 = a?.childMdx?.frontmatter?.dateInMili;
      const value2 = b?.childMdx?.frontmatter?.dateInMili;
      return value1 > value2 ? -1 : value1 < value2 ? 1 : 0;
    });
  return newsList;
};

export const truncateString = (str, num) => {
  if (str && str.length > num) {
    return str.slice(0, num) + "...";
  } else {
    return str;
  }
};

export const categoryColor = (category, categoryColors) => {
  const { Partnership, Products, Technology } = categoryColors;
  return category === "Products"
    ? Products
    : category === "Technology"
    ? Technology
    : category === "Partnership"
    ? Partnership
    : "#8c8c8c";
};

export const convertDate = (date: string) => {
  if (!isEmpty(date)) {
    const dateArray = date.split("/");
    const day = dateArray[0];
    const monthNum = dateArray[1];
    let month = "";
    const year = dateArray[2];
    switch (monthNum) {
      case "01":
        month = "Jan";
        break;
      case "02":
        month = "Feb";
        break;
      case "03":
        month = "Mar";
        break;
      case "04":
        month = "Apr";
        break;
      case "05":
        month = "May";
        break;
      case "06":
        month = "Jun";
        break;
      case "07":
        month = "Jul";
        break;
      case "08":
        month = "Aug";
        break;
      case "09":
        month = "Sep";
        break;
      case "10":
        month = "Oct";
        break;
      case "11":
        month = "Nov";
        break;
      case "12":
        month = "Dec";
        break;

      default:
        month = "Jan";
        break;
    }
    return `${day} ${month} ${year}`;
  } else {
    return "";
  }
};

export const checkPhoneNumberValid = (phoneNumber, countryCode = 0) => {
  let value;
  if (countryCode === 0) {
    value = phoneNumber;
  } else {
    value = countryCode + phoneNumber;
  }
  let result = isValidNumber(value);
  return result;
};
